import { api, base } from './ajax'
import { AuthenticationService } from './authentication-service'
import { isNilOrEmpty, getObjectKey, parseDto, isNil } from '../helpers'
import {
    getAllIncludeElementsForSections,
    getAllIncludeParamForSections,
    getSectionsForScreens,
    mergeObject,
} from '../helpers/screen-configuration-helper'

const requestMaping = '/shoppingcart/'

export const CartService = {
    myShoppingCart(forScreens, runInBackground) {
        let uniqueIncludeParams = []
        let includeElementsForSections = []

        let aDefaultIncludes = [
            'includesShoppingCartItem',
            'includesShoppingCartItem.linksShoppingCartItemProduct:Product',
            'includesShoppingCartItem.linksShoppingCartItemProduct:Product.hasUnitofMeasure:UnitofMeasurement',
            'includesShoppingCartItem.linksShoppingCartItemProduct:Product.definesAnnotation',
            'includesShoppingCartItem.includesShoppingCartItem',
            'includesShoppingCartItem.includesShoppingCartItem.linksShoppingCartItemProduct:Product',
            'includesShoppingCartItem.includesShoppingCartItem.linksShoppingCartItemProduct:Product.definesAnnotation',
            'includesShoppingCartItem.includesShoppingCartItem.linksShoppingCartItemProduct:Product.hasUnitofMeasure:UnitofMeasurement',
        ]

        if (isNilOrEmpty(forScreens)) {
            uniqueIncludeParams = ['includesShoppingCartItem']
        } else {
            const sections = getSectionsForScreens(forScreens)
            let dynamicIncludeParams = getAllIncludeParamForSections(sections)
            dynamicIncludeParams = dynamicIncludeParams.map(
                (p) => 'includesShoppingCartItem.' + p
            )

            dynamicIncludeParams = dynamicIncludeParams.concat(aDefaultIncludes)
            uniqueIncludeParams = [...new Set(dynamicIncludeParams)]

            includeElementsForSections = getAllIncludeElementsForSections(
                sections
            )
        }

        let data = {
            type: 'search',
            include: uniqueIncludeParams,
        }

        let includElementsParam = includeElementsForSections.includeElements
        if (!isNilOrEmpty(includElementsParam)) {
            let defaultIncludeElements = {
                'includesShoppingCartItem.linksShoppingCartItemProduct:Product': {
                    bda: ['label', 'objectName', 'objectId', 'objectERPId'],
                    bra: [
                        'definesAnnotation',
                        'containsKnowledgeBase',
                        'complementary',
                    ],
                },
                'includesShoppingCartItem.includesShoppingCartItem.linksShoppingCartItemProduct:Product.hasUnitofMeasure:UnitofMeasurement': {
                    bda: ['objectName', 'objectERPId'],
                    bra: [],
                },
                'includesShoppingCartItem.linksShoppingCartItemProduct:Product.hasUnitofMeasure:UnitofMeasurement': {
                    bda: ['objectName', 'objectERPId'],
                    bra: [],
                },
            }

            let tempIncludElementsParam = {}
            for (let [key, value] of Object.entries(includElementsParam)) {
                tempIncludElementsParam[
                    'includesShoppingCartItem.' + key
                ] = value
            }

            const finalIncludElementsParam = mergeObject(
                defaultIncludeElements,
                tempIncludElementsParam
            )

            data = {
                ...data,
                includeElements: finalIncludElementsParam,
            }
        }

        return api
            .post(
                `${requestMaping + 'own'}`,
                {
                    data: data,
                },
                {
                    headers: {
                        Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                            'oauthToken'
                        ),
                    },
                    trackThisPromise: !runInBackground,
                }
            )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(' Error while getting cart' - error)
                return {}
            })
    },

    myShoppingCartForContext() {
        let aDefaultIncludes = ['includesShoppingCartItem']

        return api
            .post(
                `${requestMaping + 'own'}`,
                {
                    data: {
                        type: 'search',
                        include: aDefaultIncludes,
                    },
                },
                {
                    headers: {
                        Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                            'oauthToken'
                        ),
                    },
                    trackThisPromise: false,
                }
            )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(' Error while getting cart' - error)
                return {}
            })
    },

    addItemToShoppingCart(cartId, item, appOption) {
        let updatedAppOption = appOption || {}
        updatedAppOption.headers = {
            Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                'oauthToken'
            ),
        }

        return api
            .post(
                `${requestMaping + cartId + '/item'}`,
                {
                    data: item,
                },
                updatedAppOption
            )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(' Error while adding item to Shopping Cart' - error)
                return {}
            })
    },

    updateItemToShoppingCart(cartId, item) {
        return api
            .patch(
                `${requestMaping + cartId + '/item/' + item.id}`,
                {
                    data: item,
                },
                {
                    headers: {
                        Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                            'oauthToken'
                        ),
                    },
                }
            )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(
                    ' Error while updating item to Shopping Cart' - error
                )
                return error || {}
            })
    },

    deleteItemFromShoppingCart(cartId, itemId) {
        return api
            .delete(`${requestMaping + cartId + '/item/' + itemId}`)
            .then((response) => {
                return true
            })
            .catch((error) => {
                console.log(
                    ' Error while deleting item from Shopping Cart' - error
                )
                return false
            })
    },

    deleteAllItemsFromShoppingCart(cartId) {
        return api
            .delete(`${requestMaping + cartId + '/item'}`)
            .then((response) => {
                return true
            })
            .catch((error) => {
                console.log(
                    ' Error while deleting all items from Shopping Cart' - error
                )
                return false
            })
    },

    copyShoppingCartItem(cartId, itemId) {
        return api
            .post(`${requestMaping + cartId + '/item/' + itemId + '/copy'}`)
            .then((response) => {
                return true
            })
            .catch((error) => {
                console.log(
                    ' Error while deleting all items from Shopping Cart' - error
                )
                return false
            })
    },

    async getProductPricing(
        products,
        accountBO,
        contactBO,
        systemCurrency,
        runInBackground
    ) {
        let trackThisPromise = isNilOrEmpty(runInBackground)
            ? true
            : !runInBackground

        return api
            .get(
                '/contact/salesInfos',
                {
                    params: {
                        contactBO: contactBO,
                    },
                },
                { trackThisPromise: trackThisPromise }
            )
            .then(
                (res) => {
                    let salesInfos = res.data

                    if (!isNilOrEmpty(salesInfos)) {
                        let salesOrg = null
                        let distributionChannel = null
                        let divison = null
                        let currency = null

                        salesInfos = parseDto(salesInfos)

                        for (let i = 0; i < salesInfos.length; i++) {
                            const salesInfo = salesInfos[i]
                            const contactSalesInfoDefault = getObjectKey(
                                salesInfo,
                                'contactSalesInfoDefault/value/0'
                            )

                            if (
                                !isNilOrEmpty(contactSalesInfoDefault) &&
                                contactSalesInfoDefault
                            ) {
                                salesOrg = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasSalesOrg/value'
                                )
                                distributionChannel = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasDistributionChannel/value'
                                )
                                divison = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasDivision/value'
                                )
                                currency = getObjectKey(
                                    salesInfo,
                                    'linksSalesInfo/valueDto/0/hasCurrency/value'
                                )
                                break
                            }
                        }

                        // if (isNilOrEmpty(salesOrg)) salesOrg = ''

                        // if (isNilOrEmpty(distributionChannel))
                        //     distributionChannel = ''

                        // if (isNilOrEmpty(divison)) divison = ''

                        if (isNilOrEmpty(currency)) currency = systemCurrency

                        // if (
                        //     !isNilOrEmpty(salesOrg) &&
                        //     !isNilOrEmpty(distributionChannel) &&
                        //     !isNilOrEmpty(divison) &&
                        //     !isNilOrEmpty(currency)
                        // )
                        return api.post(
                            '/pricing/product',
                            {
                                data: {
                                    hasAccount: accountBO,
                                    hasSalesOrg: salesOrg,
                                    hasDistributionChannel: distributionChannel,
                                    hasDivision: divison,
                                    hasCurrency: currency,
                                    linksItem: products,
                                },
                            },
                            { trackThisPromise: trackThisPromise }
                        )
                        // return {
                        //     data: [
                        //         {
                        //             netPrice: 666.43,
                        //             listPrice: 811.23,
                        //             discount: -144.8,
                        //             linksProduct:
                        //                 'http://www.inmindcloud.com/application/products/products-implementation.owl#803',
                        //             includesPriceItemDetail: [
                        //                 {
                        //                     baseAmountValue: '1000.0000',
                        //                     targetAmountValue: '811.2300',
                        //                     hasPriceItemType: 'PR00',
                        //                     hasBaseCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#USD',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '-5.0000',
                        //                     targetAmountValue: '-40.5600',
                        //                     hasPriceItemType: 'KA00',
                        //                     hasBaseCurrency: '',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '-3.0000',
                        //                     targetAmountValue: '-23.1200',
                        //                     hasPriceItemType: 'K007',
                        //                     hasBaseCurrency: '',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '-100.0000',
                        //                     targetAmountValue: '-81.1200',
                        //                     hasPriceItemType: 'K004',
                        //                     hasBaseCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#USD',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '0.0000',
                        //                     targetAmountValue: '0.0000',
                        //                     hasPriceItemType: 'RC00',
                        //                     hasBaseCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //             ],
                        //         },
                        //         {
                        //             netPrice: 333.21,
                        //             listPrice: 405.61,
                        //             discount: -72.4,
                        //             linksProduct:
                        //                 'http://www.inmindcloud.com/application/products/products-implementation.owl#50Z3',
                        //             includesPriceItemDetail: [
                        //                 {
                        //                     baseAmountValue: '500.0000',
                        //                     targetAmountValue: '405.6100',
                        //                     hasPriceItemType: 'PR00',
                        //                     hasBaseCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#USD',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '-5.0000',
                        //                     targetAmountValue: '-20.2800',
                        //                     hasPriceItemType: 'KA00',
                        //                     hasBaseCurrency: '',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '-3.0000',
                        //                     targetAmountValue: '-11.5600',
                        //                     hasPriceItemType: 'K007',
                        //                     hasBaseCurrency: '',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '-50.0000',
                        //                     targetAmountValue: '-40.5600',
                        //                     hasPriceItemType: 'K004',
                        //                     hasBaseCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#USD',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //                 {
                        //                     baseAmountValue: '0.0000',
                        //                     targetAmountValue: '0.0000',
                        //                     hasPriceItemType: 'RC00',
                        //                     hasBaseCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                     hasTargetCurrency:
                        //                         'http://www.inmindcloud.dev.com/application/application-implementation.owl#EUR',
                        //                 },
                        //             ],
                        //         },
                        //     ],
                        // }
                        // else return null
                    } else return null
                },
                function () {
                    return null
                }
            )
    },
    addQuoteToShoppingCart(quoteId, cartId, cartItemId) {
        return api
            .post(
                `${
                    requestMaping +
                    cartId +
                    '/item/' +
                    cartItemId +
                    '/copyFromQuote'
                }`,
                {
                    params: { quoteId: quoteId },
                 /*   headers: {
                        Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                            'oauthToken'
                        ),
                    },*/
                    trackThisPromise: true,
                }
            )
            .then((response) => {
                return response.data
            })
    },

    copyConfigToQuote(quoteId, salesItemId, cartId, cartItemId) {
        let params = new URLSearchParams()
        params.append('salesItemId', salesItemId)
        params.append('quoteId', btoa(quoteId))
        console.log(quoteId)
        console.log(salesItemId)
        console.log(cartId)
        console.log(cartItemId)
        return api
            .post(
                `${
                    requestMaping +
                    cartId +
                    '/item/' +
                    cartItemId +
                    '/copyToQuote'
                }`,
                {
                    params: params,
                    headers: {
                        Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                            'oauthToken'
                        ),
                    },
                    trackThisPromise: true,
                }
            )
            .then((response) => {
                return response.data
            })
    },

    reConfigure(
        cartId,
        cartItemId,
        currencyBO,
        accountBO,
        salesOrg,
        distributionChannel,
        divison
    ) {
        return api
            .post(
                `${
                    requestMaping +
                    cartId +
                    '/item/' +
                    cartItemId +
                    '/reConfigure'
                }`,
                {
                    data: {
                        type: 'Quote',
                        hasCurrency: currencyBO,
                        quoteContainedBy: accountBO,
                        ...(!isNilOrEmpty(salesOrg) && {
                            hasSalesOrg: salesOrg,
                        }),
                        ...(!isNilOrEmpty(distributionChannel) && {
                            hasDistributionChannel: distributionChannel,
                        }),
                        ...(!isNilOrEmpty(divison) && { hasDivision: divison }),
                    },
                  /*  headers: {
                        Authorization: AuthenticationService.getLocalStorageOAuthTokenValue(
                            'oauthToken'
                        ),
                    },*/
                    trackThisPromise: true,
                }
            )
            .then((response) => {
                return response.data
            })
    },

    submitInquiry(cartId, data, cartItemIds) {
        let params = new URLSearchParams()
        cartItemIds.forEach((itemId) => {
            params.append('cartItemIds', itemId)
        })

        return api
            .post(`${requestMaping + cartId + '/createQuote'}`, {
                params: params,
             
                trackThisPromise: true,
                data: data,
            })
            .then((response) => {
                return response.data
            })
    }
}
